import React, {Dispatch, SetStateAction, useEffect} from 'react';

// RTK Queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';

// Hooks and methods
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {twMerge} from 'tailwind-merge';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {AdminBusinessExpenseForm} from './admin-business-expense-form';

// Types
import {BusinessExpense} from '@compt/types/business-expenses/business-expense';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {
  BusinessExpenseStatus,
  expenseReportStatusFormats,
} from '@compt/types/business-expenses/business-expense-statuses';

interface AdminBusinessExpenseSidePanelProps {
  selectedExpense: BusinessExpense | null;
  setSelectedExpense: Dispatch<SetStateAction<BusinessExpense | null>>;
  expenseList: BusinessExpense[];
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setExpenseToReject: Dispatch<SetStateAction<BusinessExpense | null>>;
  handleExpenseStatusUpdate: (
    id: number,
    action: BusinessExpenseStatus,
    rejectionReason?: string,
  ) => void;
}

export const AdminBusinessExpenseSidePanel = (props: AdminBusinessExpenseSidePanelProps) => {
  const isMobileView = useIsMobileView();

  const {selectedExpense, setSelectedExpense, editMode, expenseList} = props;

  const sessionQuery = useGetSessionQuery();
  const isFinancialReviewer = sessionQuery.data?.roles.includes(USER_ROLES.financeReviewer);

  const currentSelectedExpenseIndex = props.expenseList.findIndex(
    (expense) => expense.id === selectedExpense?.id,
  );

  let pillName;
  let pillStyle;

  if (selectedExpense?.status) {
    const {statusName, statusStyle} = expenseReportStatusFormats[selectedExpense.status];
    pillName = statusName;
    pillStyle = statusStyle;
  }

  const isUnapprovedExpense =
    selectedExpense?.status !== BusinessExpenseStatus.APPROVED &&
    selectedExpense?.status !== BusinessExpenseStatus.APPROVED_BY_MANAGER;

  const isApprovedByManager = selectedExpense?.status === BusinessExpenseStatus.APPROVED_BY_MANAGER;

  const showUpdateButtons =
    selectedExpense?.status === BusinessExpenseStatus.SUBMITTED ||
    selectedExpense?.status === BusinessExpenseStatus.APPROVED_BY_MANAGER;

  const showPreviousAndNextButtons =
    !isMobileView ||
    (isMobileView &&
      (selectedExpense?.status === BusinessExpenseStatus.APPROVED ||
        selectedExpense?.status === BusinessExpenseStatus.REJECTED));

  const previousExpense = props.expenseList[currentSelectedExpenseIndex - 1];
  const nextExpense = props.expenseList[currentSelectedExpenseIndex + 1];

  // Sync changes to selected expense
  useEffect(() => {
    if (!expenseList || !selectedExpense) return;
    const selectedExpenseInExpenseList = expenseList.find(
      (expense) => expense.id === selectedExpense.id,
    );

    if (!selectedExpenseInExpenseList) {
      setSelectedExpense(null);
    }

    setSelectedExpense(selectedExpenseInExpenseList as BusinessExpense);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseList, setSelectedExpense]);

  function handlePreviousOrNextButton(expenseToNavigate: BusinessExpense) {
    if (!expenseToNavigate) return;

    props.setSelectedExpense(() => expenseToNavigate);
  }

  function handleApproveButton() {
    if (!selectedExpense) return;

    if (isFinancialReviewer && isApprovedByManager) {
      props.handleExpenseStatusUpdate(selectedExpense.id, BusinessExpenseStatus.APPROVED);
      return;
    }

    if (isUnapprovedExpense) {
      props.handleExpenseStatusUpdate(
        selectedExpense.id,
        BusinessExpenseStatus.APPROVED_BY_MANAGER,
      );
    }
  }

  return (
    <ComptSidePanel
      open={!!selectedExpense}
      className="max-w-[920px]"
      data-testid={`admin-business-expense-side-panel-${selectedExpense?.id}`}
    >
      <ComptSidePanel.Header
        title="Review business expense"
        setOpen={() => props.setSelectedExpense(null)}
        statusPill={
          selectedExpense?.status &&
          !isMobileView && (
            <span
              className="flex"
              data-testid={`status-pill-${selectedExpense?.status.toLowerCase()}`}
            >
              <h2 className="text-color-body1">&nbsp;Status</h2>
              <ComptPill className="ml-2" pillSize={ComptPillSize.MEDIUM} pillType={pillStyle}>
                {pillName}
              </ComptPill>
            </span>
          )
        }
      />
      <AdminBusinessExpenseForm
        selectedExpense={selectedExpense}
        editMode={editMode}
        setEditMode={props.setEditMode}
      />
      {!editMode && (
        <ComptSidePanel.Footer>
          <div className="flex w-full justify-between">
            {showUpdateButtons && (
              <div className="flex flex-col w-full sm:flex-row gap-y-2 sm:gap-x-2">
                <ComptButton
                  buttonType={ComptButtonType.PRIMARY}
                  onClick={handleApproveButton}
                  data-testid={`admin-be-approve-button-${selectedExpense.id}`}
                >
                  Approve
                </ComptButton>
                {isApprovedByManager && (
                  <ComptButton
                    data-testid={`admin-be-unapprove-button-${selectedExpense.id}`}
                    onClick={() =>
                      props.handleExpenseStatusUpdate(
                        selectedExpense.id,
                        BusinessExpenseStatus.SUBMITTED,
                      )
                    }
                    buttonType={ComptButtonType.DESTRUCTIVE}
                  >
                    Unapprove
                  </ComptButton>
                )}
                <ComptButton
                  buttonType={ComptButtonType.SECONDARY}
                  onClick={() => props.setEditMode(true)}
                  data-testid={`admin-be-edit-button-${selectedExpense.id}`}
                >
                  Edit
                </ComptButton>
                <ComptButton
                  data-testid={`admin-be-reject-button-${selectedExpense.id}`}
                  onClick={() => props.setExpenseToReject(selectedExpense)}
                  buttonType={ComptButtonType.DESTRUCTIVE}
                >
                  Reject
                </ComptButton>
              </div>
            )}
            {showPreviousAndNextButtons && (
              <div className="flex w-full space-x-3 ml-auto justify-between sm:justify-end">
                <ComptButton
                  buttonType={ComptButtonType.BORDERLESS}
                  disabled={!previousExpense}
                  onClick={() => handlePreviousOrNextButton(previousExpense)}
                  textClassName={twMerge(
                    `${
                      !previousExpense ? 'text-disabled-on-light' : 'text-color-link'
                    } inline-block`,
                  )}
                >
                  Previous expense
                </ComptButton>
                <ComptButton
                  buttonType={ComptButtonType.BORDERLESS}
                  disabled={!nextExpense}
                  onClick={() => handlePreviousOrNextButton(nextExpense)}
                  textClassName={twMerge(
                    `${!nextExpense ? 'text-disabled-on-light' : 'text-color-link'}`,
                  )}
                >
                  Next expense
                </ComptButton>
              </div>
            )}
          </div>
        </ComptSidePanel.Footer>
      )}
    </ComptSidePanel>
  );
};
